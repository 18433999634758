import React from 'react';

import {
  parseAccount,
  getFixFieldMaxAndMin,
  getCurrentKnoList,
} from 'helpers/main';
import { KASPI_GOLD, KASPI_CREDIT } from 'constants/services';
import KbkList from 'public/json/finesAndTaxes/kbk';
import KnpList from 'public/json/finesAndTaxes/knp';

export const getAddingsAmount0 = (amount0, fix) => {
  let amount = '';

  // eslint-disable-next-line eqeqeq
  if (amount0 != '') {
    amount = Number(parseFloat(amount0).toFixed(2));
  }

  // Если min, max, fix (из account) пришли ли в онлайн-проверке - затираем старые
  if (Number(fix)) {
    amount = Number(parseFloat(fix).toFixed(2));
  }

  return amount;
};

export const getAddingsAmount1 = (min, amount1, minServiceValue, fixAmountField, formData) => {
  let amount = '';
  let minAmount = 0;

  // Изначально устанавливаем в min и max значения с информации по сервису
  if (Number(minServiceValue)) {
    amount = Number(minServiceValue);
  }

  // Если есть поле с фиксированное суммой к оплате, берем max и min оттуда
  if (typeof fixAmountField.id !== 'undefined') {
    const { id, sum } = fixAmountField;
    const defaultData = { value: formData.amount, sum };
    const data = (typeof id !== 'undefined') ? fixAmountField : defaultData;

    minAmount = getFixFieldMaxAndMin(data).min;
    amount = Number(minAmount);
  }

  if (Number(min)) {
    amount = Number(parseFloat(min).toFixed(2));
  }

  if (amount1) {
    amount = amount1;
  }

  return amount;
};

export const getAddingsAmount2 = (max, amount2, maxServiceValue, fixAmountField, formData) => {
  let amount = '';
  let maxAmount = 0;

  // Изначально устанавливаем в min и max значения с информации по сервису
  if (Number(maxServiceValue)) {
    amount = Number(maxServiceValue);
  }
  // Если есть поле с фиксированное суммой к оплате, берем max и min оттуда
  if (typeof fixAmountField.id !== 'undefined') {
    const { id, sum } = fixAmountField;
    const defaultData = { value: formData.amount, sum };
    const data = (typeof id !== 'undefined') ? fixAmountField : defaultData;

    maxAmount = getFixFieldMaxAndMin(data).max;

    if (maxAmount != null) {
      amount = Number(maxAmount);
    }
  }

  if (Number(max)) {
    amount = Number(parseFloat(max).toFixed(2));
  }

  // если есть min || max суммы у option (79 payScheme), пишем их, у них приоритет самый высокий

  if (amount2) {
    amount = amount2;
  }

  return amount;
};

export const getAddingsCheckTypeInterfaceGetAmount0 = (type, amount) => {
  if (type === 'fix') return parseFloat(amount).toFixed(2);

  return '';
};

export const getAddingsCheckTypeInterfaceGetAmount1 = (type, amount) => {
  if (type === 'min') return parseFloat(amount).toFixed(2);

  return '';
};

export const getAddingsCheckTypeInterfaceGetAmount2 = (type, amount) => {
  if (type === 'max') return parseFloat(amount).toFixed(2);

  return '';
};

export const calculateConstraint = (name, id, mask, formData, fieldOptions, fixAmountField) => {
  let constraint = '';

  if (name !== 'label' && Number(id) !== 1) {
    if (typeof formData[name.toString()] !== 'undefined') {
      if (fieldOptions === null) {
        constraint = `${parseAccount(mask, formData[name.toString()])}|`;
      } else {
        constraint = `${formData[name.toString()]}|`;
      }
    } else if (name === 'fix' && typeof fixAmountField.value !== 'undefined') {
      constraint = `${fixAmountField.value}|`;
    }
  }

  return constraint;
};

export const getAccountHandler = (formData, account, maskForAccount) => {
  if (formData.account === account) {
    return `${account}`;
  }

  if (account.split(';').length) {
    const newAccount = account.split(';')[0];

    if (formData.account === newAccount) {
      return `${account}`;
    }
  }

  if (maskForAccount) {
    return `${parseAccount(maskForAccount, formData.account)}${account}`;
  }

  return `${formData.account}${account}`;
};

export const confirmFeldsCheckInfo = (formData, id, fieldName) => {
  const name = (Number(id) === 1) ? 'account' : fieldName;

  if (typeof formData[name.toString()] !== 'undefined') {
    return formData[name.toString()];
  }

  return null;
};

export const confirmCheckInfoGetValue = (name, value) => {
  if (name === 'Сумма к оплате') return parseFloat(value).toFixed(2).toString();

  return value.toString();
};

export const confirmConfirmFieldsGetValue = (name, value) => {
  if (name === 'Сумма к оплате') return parseFloat(value).toFixed(2);

  return value;
};

export const confirmCaseTypeInterface79 = (selectedOptionData) => ({
  name: selectedOptionData.length ? selectedOptionData[0]['@attributes'].name : '',
  value: selectedOptionData.length ? selectedOptionData[0]['@attributes'].id : '',
});

export const getDynamicFieldsItem = (item) => {
  if (item) {
    const { content, '@attributes': { name } } = item;

    if (name) {
      return <p key={name}><b>{name}: </b>{content}</p>;
    }
    if (content) {
      return <p key="key">{content}</p>;
    }
  }

  return null;
};

export const getIsCountInputDisabled = (selectedOptionData) => {
  let isCountInputDisabled = false;

  if (selectedOptionData.length) {
    isCountInputDisabled = (!selectedOptionData[0]['@attributes'].count);
  }

  return isCountInputDisabled;
};

export const getDefaultValueInRender = (editedCounts, selected) => {
  let defaultValue = 1;

  if (typeof editedCounts[selected.toString()] === 'number') {
    defaultValue = editedCounts[selected.toString()];
  }

  return defaultValue;
};

const getServiceOnId = ({ idService, serviceList }) =>
  serviceList.find(item => (item.idService === idService));

export const getServiceNonExistError = ({ otherIdServiceForMakePay, serviceList }) => {
  if (otherIdServiceForMakePay === KASPI_GOLD.SERVICE_ID) {
    const idService = KASPI_GOLD.SERVICE_ID;
    const isKaspiGoldExistOnTerminal = getServiceOnId({ idService, serviceList });

    if (!isKaspiGoldExistOnTerminal) {
      return {
        errMessage: 'Обратитесь к курирующему менеджеру',
        errTitle: 'Сервис "Пополнение Kaspi Gold" отсутствует на Вашем терминале'
      };
    }
  }

  if (otherIdServiceForMakePay === KASPI_CREDIT.SERVICE_ID) {
    const idService = KASPI_CREDIT.SERVICE_ID;
    const isKaspiCreditExistOnTerminal = getServiceOnId({ idService, serviceList });

    if (!isKaspiCreditExistOnTerminal) {
      return {
        errMessage: 'Обратитесь к курирующему менеджеру',
        errTitle: 'Сервис "Погашение Kaspi Кредита" отсутствует на Вашем терминале'
      };
    }
  }

  return null;
};

export const getKbkSelectOptions = () => {
  const sortedKbk = [];

  Object.keys(KbkList).forEach((key) =>
    sortedKbk.push({ [key]: `${key} | ${KbkList[key.toString()]}` })
  );

  return sortedKbk;
};

export const getKnoSelectOptions = (idService) => {
  const sortedKno = [];
  const KnoList = getCurrentKnoList(idService);

  Object.keys(KnoList).forEach((key) => {
    if (key.endsWith('0101') || (key.length === 4 && key.endsWith('01'))) {
      const pos = key.length === 4 ? key.length - 2 : key.length - 4;

      sortedKno.push({ [key]: `${key.slice(0, pos)} | ${KnoList[key.toString()]}` });
    }
  });

  return sortedKno;
};
export const getKnpSelectOptions = () => {
  const sortedKnp = [];

  Object.keys(KnpList).forEach((key) =>
    sortedKnp.push({ [key]: `${key} | ${KnpList[key.toString()]}` })
  );

  return sortedKnp;
};

export const getUgdSelectOptions = (nd, idService) => {
  const sortedUgd = [];
  const KnoList = getCurrentKnoList(idService);

  const kno = Object.keys(KnoList);

  if (kno.length && nd) {
    kno.forEach((key) => {
      if (key.slice(0, 2) === nd.slice(0, 2)) {
        sortedUgd.push({ [key]: `${key} | ${KnoList[key.toString()]}` });
      }
    });

    return (sortedUgd);
  }
};
