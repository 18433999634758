// Список сервисов, для чеков которых фискализация не нужна
import React from 'react';

export const IGNORED_SERVICES_FOR_FISCAL = [2737, 4081, 6166, 6179, 6180];

export const ALMATYENERGOSBYT_UR_LITCA = 7962;
export const JYSAN_CARD_REPLENISHMENT = 8107;
export const ALMATYENERGOSBYT_OBLAST = 8034;
export const CASH2CARD_KASSA24 = 9115;
export const MYPOST_PAYMENT_SERVICE_ID = 9264;
export const MYPOST_DELIVERY_SERVICE_ID = 9315;
export const CASHOUT_SERVICE_ID = 9224;
export const CASHOUT_SERVICES_TAB_ID = 1001;
export const MYPOST_SERVICES_TAB_ID = 1002;
export const KTJ_SERVICE_ID = 2485;
export const PAY_FOR_FISCAL_SERVICE_ID = 8181; // Fiscal24 - оплата по счету
export const ENERGOPOTOK_SERVICE_ID = 9704;
export const PENALTIES_AND_TAXIES_SCHEME = 73;
export const SERVICES_WITH_KBK_KNO_IN_RECEIPT = [9965, 9984];
export const BCK_SERVICES_WITH_KBK_KNO_SELECT = [10459, 11233];
export const OLIMPBET_SERVICES = {
  ID: [10541, 10542, 10543],
  ALERT_INFO:
  <>
    Уведомляем Вас об ограничениях при пополнении личного счета в
    Букмекерских конторах через терминалы Kassa24.
    <p>С 05.12.19 года максимальная сумма одной транзакции составляет
      500 000 тенге, минимальная составляет 200 тенге.
    </p>
  </>
};

export const KASPI_GROUP_ID = 573;
export const KASPI_GOLD = {
  SERVICE_ID: 9498,
  SERVICE_LOGO: 'o09498_1.png',
  SERVICE_INFO: {
    id: 9498,
    name: 'Пополнение Kaspi Gold',
    isEMoney: 0,
    mask: '',
    scheme: 79,
    fillWithStatic: false
  },
  ALERT_INFO:
  <>
    Обратите внимание! По данному ИИН не найден договор на пополнение Kaspi Gold.
    Вы можете выбрать только договор на <b>погашение кредита.</b>
  </>
};

export const KASPI_CREDIT = {
  SERVICE_ID: 9565,
  SERVICE_LOGO: 'o09565_1.png',
  SERVICE_INFO: {
    id: 9565,
    name: 'Погашение Kaspi Кредита',
    isEMoney: 0,
    mask: '',
    scheme: 79,
    fillWithStatic: false
  },
  ALERT_INFO:
  <>
    Обратите внимание! По данному ИИН не найден договор на погашение кредита.
    Вы можете выбрать только договор на <b>пополнение Kaspi Gold.</b>
  </>
};

export const KASPI_LOGO = {
  [KASPI_GOLD.SERVICE_ID]: KASPI_GOLD.SERVICE_LOGO,
  [KASPI_CREDIT.SERVICE_ID]: KASPI_CREDIT.SERVICE_LOGO,
};

export const KASPI_SERVICE_NAME = {
  [KASPI_GOLD.SERVICE_ID]: KASPI_GOLD.SERVICE_INFO.name,
  [KASPI_CREDIT.SERVICE_ID]: KASPI_CREDIT.SERVICE_INFO.name,
};

// до тех пор пока не реализуем запрос кода для чека
export const BLOCKED_SERVICES = [
  5003, // Wi-FikSpot
];

// Сервисы для которых запрашивается код после оплаты, и только потом сохраняется чек
export const SERVICES_WITH_CODE_FOR_CHECK = [
  6181, // Жамбыл Жарык Сауда
  5003, // Wi-FikSpot
];

export const SERVICES_WITH_CONTRACT = [
  8190, // Фридом Финанс Кредит
  8753 // Фридом Финанс Кредит МФО
];
export const SERVICES_STATUSES_WITH_ONLINE_CHECK = [2, 3];
export const SERVICE_TYPES = [
  { id: 1, title: 'Телефония' },
  { id: 3, title: 'Финансовые организации' },
  { id: 4, title: 'Коммунальные платежи' },
  { id: 5, title: 'Интернет и IP телефония' },
  { id: 6, title: 'Телевидение' },
  { id: 10, title: 'Электронные деньги' },
  { id: 12, title: 'Прочие платежи' },
  { id: 16, title: 'Авто-, авиа-, ж/д билеты' },
  { id: 17, title: 'Иностранные операторы' },
  { id: 21, title: 'Налоги и штрафы' },
  { id: 22, title: 'Скидочные сервисы' },
  { id: 23, title: 'Букмекеры' },
  { id: 24, title: 'Косметика' },
  { id: 26, title: 'Личная Касса24' },
  { id: 27, title: 'Онлайн-игры' },
  { id: 28, title: 'Такси и грузоперевозки' },
  { id: 29, title: 'Интернет-сайты' },
  { id: 30, title: 'Авто и запчасти' },
  { id: 31, title: 'Туроператоры' },
  { id: 32, title: 'Услуги образования' },
];

export const TAXES_WITH_OLD_KNO = [2414, 2415];
export const TAXES_GROUP_SERVICE = 609;
