/* eslint-disable import/extensions */
import React from 'react';
import moment from 'moment';
import base64 from 'base-64';
import 'moment/locale/ru';
import * as Sentry from '@sentry/react';
import { store } from 'react-notifications-component';
import { XMLBuilder } from 'fast-xml-parser';

import KnoNewList from 'public/json/finesAndTaxes/kno.json';
import KnoOldList from 'public/json/finesAndTaxes/knoOld.json';
import { ERROR_CODE, ERROR_CODE_TYPES } from 'constants/all';
import { PAY_TYPES_WITHOUT_CONVERT_ACCOUNT, INIT_PAY_TYPE, PAY_SCHEMES } from 'constants/payTypes';

import { getAuthData } from 'model/UserDb';
import CustomButton from 'components/UI/Button/button';
import localeData from 'public/json/locales.json';
import { TAXES_WITH_OLD_KNO } from 'constants/services';

const CryptoJS = require('crypto-js');
const pako = require('pako');

export const captureException = (err, reqType, actionType, errorSource = '') => {
  if (!window.location.href.includes('localhost')) {
    Sentry.withScope((scope) => {
      const { idTerminal } = getAuthData();

      scope.setTag('errorSource', errorSource);
      scope.setTag('idTerminal', idTerminal);
      scope.setTag('reqType', reqType);
      scope.setTag('actionType', actionType);

      if (typeof err === 'object') {
        return Sentry.captureException(JSON.stringify(err));
      }

      if (typeof err === 'string') {
        return Sentry.captureException(new Error(err));
      }
    });
  }
};

export function logErrorToSentry(msg, reqType, httpStatus, actionType, logData = '', errMsg = '') {
  if (!window.location.href.includes('localhost')) {
    let err = msg;
    const { idTerminal } = getAuthData();

    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setTag('idTerminal', idTerminal);
      scope.setTag('httpStatus', httpStatus);
      scope.setTag('reqType', reqType);
      scope.setTag('actionType', actionType);
      scope.setExtra('logData', logData);

      if (typeof err === 'object') {
        err = JSON.stringify(msg);
      }

      Sentry.captureMessage(`Терминал (${idTerminal}): ${err}. ${errMsg}`);
    });
  }
}

export function logWarningToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    const { idTerminal } = getAuthData();

    Sentry.captureMessage(`Терминал (${idTerminal}): ${text}`, 'warning');
  }
}

export function logInfoToSentry(text) {
  if (!window.location.href.includes('localhost')) {
    const { idTerminal } = getAuthData();

    Sentry.captureMessage(`Терминал (${idTerminal}): ${text}`, 'info');
  }
}

export function getErrText(code, type = '') {
  let errorText = '';

  if (!(getObjectElement(ERROR_CODE, code))) errorText = 'Возникла ошибка. Повторите попытку позже.';
  else if (code === 715) errorText = `${getObjectElement(ERROR_CODE_TYPES, type)} ${getObjectElement(ERROR_CODE, code)}`;
  else errorText = getObjectElement(ERROR_CODE, code);

  return errorText;
}

export function setSentryBreadсrumbs(category, message) {
  Sentry.addBreadcrumb({
    category,
    message,
    level: 'info',
  });
}

export const t = (key, lang = 'ru') => localeData[lang.toString()][key.toString()] || key;

export const getInitTypeByPayType = ({ payType }) =>
  typeof INIT_PAY_TYPE[Number(payType)] === 'undefined' ?
    INIT_PAY_TYPE[0] :
    INIT_PAY_TYPE[Number(payType)];

export function formatMoney(x, scope = 0) {
  const p = /(-?\d+)(\d{3})/;

  let coins = '00';
  const list = x.toString().replace(',', '.').split('.');
  let y = list[0];

  if (y.length === 0) { y = '0'; }
  while (p.test(y)) {
    y = y.replace(p, '$1 $2');
  }
  if (y === 'NaN') { y = '0'; }
  if (list.length === 1) {
    return y;
  }
  if (scope > 0) {
    coins = list[1];

    return `${y}.${coins.slice(0, scope)}`;
  }

  return y;
}

export function convertUnicode(input) {
  return input.replace(/\\u(\w\w\w\w)/g, (a, b) => {
    const charcode = parseInt(b, 16);

    return String.fromCharCode(charcode);
  });
}

// Проверка на спец. символы в маске лицевого счета
// Если есть доллар значит надо отправить все символы, которые были помечены тильдой, иначе удалить
export function parseAccount(maskEdit, value) {
  if (maskEdit && value) {
    const mask = maskEdit.toString();
    const account = value.toString();

    if (mask[0] === '$') return account;

    let total = '';
    let count = 0;
    let j = 0;
    let i = 0;

    for (i; i < mask.length; i += 1) {
      let x = 0;

      if (mask[Number(j)] === '`') {
        x = 1;
        count += 1;
        j++;
      }

      const totalRes = parseAccountGetTotal(mask, j, x, account, count);

      if (totalRes) total += totalRes;
      j++;
    }

    return total;
  }

  return value;
}

const parseAccountGetTotal = (mask, i, x, account, count) => {
  let total = '';

  if (mask[Number(i)] !== '`') {
    if (x === 0) {
      if (typeof account[i - count] !== 'undefined') {
        total += account[i - count];
      }
    }
  }

  return total;
};

export function checkUnicodeSymbols(str) {
  return str.toString()
    .replace(/&amp;#1241;/g, 'ә')
    .replace(/&amp;#1201;/g, 'ұ')
    .replace(/&amp;#1199;/g, 'ү')
    .replace(/&amp;#1179;/g, 'қ')
    .replace(/&amp;#1257;/g, 'ө')
    .replace(/&amp;#1171;/g, 'ғ')
    .replace(/&#13;/g, ' ');
}

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, /* NOSONAR */
    (match, p1) => String.fromCharCode(`0x${p1}`)));
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.

  return decodeURIComponent(atob(str).split('').map((c) => { /* NOSONAR */
    const charCode = (`00${c.charCodeAt(0).toString(16)}`);

    return `%${charCode.slice(-2)}`;
  }).join(''));
}

export function checkAccount(account, amount, reg, minAmount, maxAmount) {
  let message = '';

  if (account !== 0) {
    // eslint-disable-next-line
    const regular = new RegExp(reg.replace(/\\\\/g, '\\'));

    if (!regular.test(account.trim())) {
      message = 'Длина номера счета меньше допустимой';
    }

    // если длина маски не равна длине введенного номера счета
    if (parseInt(amount, 10) < parseInt(minAmount, 10)) {
      message = `Минимальная сумма оплаты: ${parseInt(minAmount, 10)} тг.`;
    } else if (maxAmount !== 0) {
      if (parseInt(amount, 10) > parseInt(maxAmount, 10)) {
        message = `Максимальная сумма оплаты: ${parseInt(maxAmount, 10)} тг.`;
      }
    }
  } else {
    message = 'Введите номер счета';
  }

  return { message };
}

export const checkAccountOnReg = (account, regStr, maskEdit) => {
  const accoutForCheck = (parseAccount(maskEdit, account));
  // eslint-disable-next-line
  const regular = new RegExp(regStr.replace(/\\\\/g, '\\'));

  return !!regular.test(accoutForCheck.trim());
};

/* Преобразование маски для оплаты для InputMask для комплексной схемы */
export function getMaskForComplexScheme(mask) {
  if (mask && mask === '$999999`.99') {
    return 'D'.repeat(9);
  }

  return getMaskForInput(mask);
}

/* Преобразование маски для оплаты для InputMask */
export function getMaskForInput(mask) {
  if (mask) {
    const newMask = mask.toString().replace(/`9/g, 'q').replace(/9/g, '1').replace(/q/g, '`9');

    return newMask.replace(/`/g, '\\').replace('$', '');
  }

  return 'W'.repeat(40);
}

// Получаем максимальную и минимальную сумму в динамических схемах (75 и 86)
export function getFixFieldMaxAndMin(amountoption) {
  const { value, sum: fixSum } = amountoption;

  let sum = fixSum;

  if (sum === null) {
    sum = [0, 0];
  }

  const maxAndMin = {};

  if (sum[0] === 0) {
    maxAndMin.min = value;
  }

  if (sum[1] === 0) {
    maxAndMin.max = value;
  }

  if (sum[1] === 1) {
    maxAndMin.max = null;
  }

  return maxAndMin;
}

// Проверяет сумму в динамических схемах (75 и 86) в соответствии с минимальной и максимальной
export function checkAmount(sum, value, amountoption, amount) {
  let result = true;
  let message = '';

  if (typeof amountoption.id !== 'undefined') {
    message = checkAmountHelp(sum, amount, value);

    result = false;
  }

  return { result, message };
}

const checkAmountHelp = (sum, amount, value) => {
  let message = '';

  if (sum[0] === 0 && sum[1] === 0) {
    if (amount !== value) {
      message = `Сумма должна быть равной - ${value}`;
    }
  } else if (sum[0] === 0 && sum[1] === 1) {
    if (amount < value) {
      message = `Сумма меньше минимальной - ${value}`;
    }
  } else if (sum[0] === 1 && sum[1] === 0) {
    if (amount > value) {
      message = `Сумма больше максимальной - ${value}`;
    }
  }

  return message;
};

export function getCapByLang(lang) {
  switch (lang) {
    case 'ru': return 'capRU';
    case 'kz': return 'capKZ';
    case 'en': return 'capEN';
    default:
  }
}

// Прошло ли 2 часа с последнего обновления сервисов
export function hasItBeenTwoHours(date) {
  if (typeof date !== 'undefined' && date) {
    const beginDate = moment(date, 'YYYYMMDDhhmmss');
    const currentDate = moment();
    const duration = moment.duration(currentDate.diff(beginDate));
    const hours = duration.asHours();

    return (hours > 2);
  }

  return true;
}

/**
 * Истекает ли указанная {date} через {countDays} дней
 * @param {new Date().toString()} date
 * @param {number} countDays
 */
export function doesTheDateEndInCountDays(date, countDays) {
  if (date && countDays) {
    const receivedDate = moment(new Date(date));
    const currentDate = moment();
    const duration = moment.duration(receivedDate.diff(currentDate));
    const days = duration.asDays();

    return days <= countDays;
  }

  return false;
}

/**
 * Истекла ли указанная дата относительно сегодняшнего числа
 * @param {new Date().toString()} date
 */
export function getCountDaysForDateExpired(date, format = 'date') {
  if (date) {
    let receivedDate = moment(`${date} 23:59:59`, 'DD.MM.YYYY HH:mm:ss');

    if (format === 'dateTime') {
      receivedDate = moment(new Date(date));
    }

    const currentDate = moment();
    const duration = moment.duration(receivedDate.diff(currentDate));
    const days = duration.asDays();

    return days >= 0 ? days : 0;
  }

  return 0;
}

export function getDateInMls(date) {
  if (typeof date !== 'undefined') {
    return moment(date, 'YYYYMMDDHHmmss').valueOf();
  }

  return moment().format('YYYYMMDDHHmmss').valueOf();
}

export function getDiffDate({ dateOut, dateIn = '19700101000000', responseType = 'milliseconds' }) {
  if (typeof dateIn !== 'undefined') {
    const beginDate = moment(dateOut, 'YYYYMMDDHHmmss');
    const endDate = moment(dateIn.toString(), 'YYYYMMDDHHmmss');

    return (endDate.diff(beginDate, responseType));
  }

  return 0;
}

export const signatureQuery = (message, code) => {
  const codeMd5 = CryptoJS.MD5(code).toString();
  const codeSHA = CryptoJS.SHA512(codeMd5).toString();
  const codeHmac = CryptoJS.HmacSHA512(message, codeSHA).toString(CryptoJS.enc.Latin1);

  return (base64.encode(codeHmac));
};

export const generateTransactionNumber = () => {
  const date = moment().unix() - 1251759600; // - 2009.09.01

  // дата + случайное пятизначное число
  return `${date}${generateRandomValue(5)}`;
};

export const generateRandomValue = (len = 10) => {
  const array = new Uint32Array(10);

  window.crypto.getRandomValues(array);

  return array[0].toString().slice(0, len);
};

export const getTheStartDateOfTheCurrentMonth = () => moment().startOf('month').format('YYYYMMDDHHmmss');

export const getTheStartDateOfTheCurrentDay = () => `${moment().format('YYYYMMDD')}000000`;

export const getTheEndOfTheCurrentDay = () => `${moment().format('YYYYMMDD')}235959`;

export function getCurrentDate() {
  return moment().format('YYYYMMDDHHmmss');
}

export function getCurrentDateWithTimezone() {
  // Получаем смещение часового пояса в минутах и преобразуем его в часы
  const timezoneOffset = -new Date().getTimezoneOffset() / 60;

  // Получаем текущее время с учетом смещения часового пояса
  return moment().add(timezoneOffset, 'hours').format('YYYYMMDDHHmmss');
}

export const getTimezoneOffset = () => -new Date().getTimezoneOffset() / 60;

export const getCurrentTimesTamp = () => moment().unix();

export const getHumanDateTime = (date = '') => {
  if (date) {
    return moment(date).format('HH:mm DD.MM.YYYY');
  }

  return moment().format('HH:mm DD.MM.YYYY');
};

export const getFormatedJsDate = (date = '') => {
  if (date && date instanceof Date && !Number.isNaN(date)) {
    return moment(date).format('DD.MM.YYYY');
  }

  return moment().format('DD.MM.YYYY');
};

export const getFormatedJsDateTime = (date = '') => {
  if (date && !Number.isNaN(date)) {
    return moment(date, 'YYYYMMDDHHmmss').format('DD.MM.YYYY HH:mm:ss');
  }

  return moment().format('DD.MM.YYYY HH:mm:ss');
};

export const getDateValueInMoment = (date = '') => {
  const dateValue = getPayFormattedDate(date);

  return (dateValue);
};

export const getAccountOnMask = ({ payType, maskEdit, account }) => {
  let accountOnMask = account;

  if (typeof maskEdit !== 'undefined') {
    if (!PAY_TYPES_WITHOUT_CONVERT_ACCOUNT.includes(payType)) {
      if (maskEdit.toString().includes('`')) {
        accountOnMask = parseAccount(maskEdit.toString(), account);
      }
    }

    if (!accountOnMask.includes('undefined')) {
      return accountOnMask;
    }
  }

  return account;
};

export const checkAddingsAndReturnBack = (adding) => {
  let xmlAddings = '';

  if (typeof adding !== 'undefined' && adding !== '') {
    const addings = [].concat(adding);

    xmlAddings = '<addings>';

    const toXmlParser = new XMLBuilder();

    addings.forEach(item => {
      const toXml = toXmlParser.build(item);

      xmlAddings += `<adding>${toXml}</adding>`;
    });

    xmlAddings += '</addings>';
  }

  return xmlAddings;
};

export function isInt(n) {
  return Number(n) % 1 === 0;
}

export function isDynamicScheme(payType) {
  return (
    payType === PAY_SCHEMES.DYNAMIC1
    || payType === PAY_SCHEMES.DYNAMIC2
    || payType === PAY_SCHEMES.DYNAMIC3
  );
}

export function showNotification() {
  const CustomContent = (
    <div className="custom-notification">
      <h2>У Вас есть важные обновления</h2>
      <h3>Обновите пожалуйста страницу, чтобы изменения в программе были актуальными</h3>
      <CustomButton style={{ margin: '10px 0 0 auto' }} type="primary" size="small" onClick={() => window.location.reload('')}>
        Обновить
      </CustomButton>
    </div >
  );

  return store.addNotification({
    type: 'warning',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    content: CustomContent

  });
}

export const getCookie = (name) => {
  // eslint-disable-next-line
  const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));

  return matches ? decodeURIComponent(matches[1]) : null;
};

export const pakoStrEncode = (s) => pako.deflate(s, { to: 'string' });

export const pakoStrDecode = (s) => pako.inflate(s, { to: 'string' });

export const imageEncode = (arrayBuffer) => {
  const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => p + String.fromCharCode(c), '')); /* NOSONAR */
  const mimetype = 'image/png';

  return `data:${mimetype};base64,${b64encoded}`;
};

export const validateEmail = (email) => {
  const re = /^(?=.{1,64}@)[^\s@]+@[^\s@]+\.[^\s@]{2,64}$/;

  return re.test(email);
};

export const getClearPhone = (phone) => phone.replace('+7').replace('8(').replace(/[^+\d]/g, '').trim();

export const imgToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(new Error(error));
});

// declOfNum(1, ['минута', 'минуты', 'минут']);
// вернёт — минута
export const declOfNum = (n, text_forms) => {
  const newVal = Math.abs(n) % 100;
  const n1 = newVal % 10;

  if (newVal > 10 && newVal < 20) return text_forms[2];
  if (n1 > 1 && n1 < 5) return text_forms[1];
  if (n1 === 1) return text_forms[0];

  return text_forms[2];
};

export const setToLocalStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const getFromLocalStorage = (name) => {
  const value = window.localStorage.getItem(name);

  if (value !== null) {
    return JSON.parse(value);
  }
};

export const getObjectElement = (obj, property) => {
  const descriptor = Object.getOwnPropertyDescriptor(obj, `${property}`);

  return descriptor ? descriptor.value : undefined;
};

export const getArrayElement = (arr, index) =>
  arr.slice(index, index + 1)[0];

export const setArrayElement = (arr, index, value) => {
  const newArr = [].concat(arr);

  if (index >= arr.length) {
    newArr.length = index + 1;
  }

  newArr.splice(index, 1, value);

  return value;
};

export const setObjectProperty = (obj, property, value) => {
  Object.defineProperty(obj, `${property}`, {
    configurable: true,
    enumerable: true,
    writable: true,
    ...Object.getOwnPropertyDescriptor(obj, property),
    value
  });

  return value;
};

export const setObjectElement = setObjectProperty;

export const isNull = (val) => val === null;

export const isPresent = (obj, key) => (obj && getObjectElement(obj, key));

export const setFocusFormElement = (formId) => {
  const formElement = document.getElementById(formId);

  if (!formElement) {
    return;
  }

  const inputs = formElement.getElementsByTagName('input');

  const inputsLength = inputs.length;
  const notDisabledInput = [];

  for (let i = 0; i < inputsLength; i++) {
    const input = getObjectElement(inputs, i);

    if (!input.disabled) {
      notDisabledInput.push(input);
    }
  }

  if (notDisabledInput.length) {
    notDisabledInput[0].focus();
  }
};

export const getPayFormattedDate = (date) => {
  const dateString = String(date);
  const payDate = dateString.slice(0, 8);
  const payTime = dateString.slice(-6);
  const dateModified = `${payDate} ${payTime}`;

  return moment(dateModified);
};

export const getShiftFormattedDate = (date) => {
  const dateShift = String(date).split('T');
  const dateShiftDate = dateShift[0];
  const dateShiftTimeModified = dateShift[1].split('.');
  const dateShiftTime = dateShiftTimeModified[0];
  const dateModified = `${dateShiftDate} ${dateShiftTime}`;

  return moment(dateModified);
};

export const getCurrentKnoList = (idService) =>
  TAXES_WITH_OLD_KNO.includes(idService) ? KnoOldList : KnoNewList;
